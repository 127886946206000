var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mew-popup",
    {
      attrs: {
        "max-width": "690px",
        show: _vm.openWithdrawalModal,
        "has-buttons": false,
        "has-body-content": true,
        title: _vm.modalTitle,
        "left-btn": _vm.leftBtn,
      },
    },
    [
      _c("div", [
        _vm.step === 1
          ? _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("mew-warning-sheet", {
                  staticClass: "mb-5",
                  attrs: {
                    title: "",
                    description:
                      "The withdrawal address can only be set once and can never be changed. Please make sure the withdrawal address you are setting is a non-custodial wallet to which you have full access with a recovery phrase or private key. Do NOT use an exchange address or a smart contract wallet.",
                  },
                }),
                _c("div", { staticClass: "mew-heading-3 mb-3 text-left" }, [
                  _vm._v("Your Withdrawal Address"),
                ]),
                _c("module-address-book", {
                  ref: "addressInput",
                  staticClass: "AddressInput",
                  attrs: {
                    label: "Address",
                    "preselect-curr-wallet-adr": true,
                    currency: _vm.currencyName,
                  },
                  on: { setAddress: _vm.setAddress },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.step === 2
          ? _c(
              "div",
              { staticClass: "text-center" },
              [
                _vm._l(_vm.buttons, function (btn, key) {
                  return _c(
                    "div",
                    { key: key, staticClass: "mb-5" },
                    [
                      _c(
                        "mew-button",
                        {
                          class: btn.class,
                          staticStyle: { height: "160px" },
                          attrs: {
                            "has-full-width": "",
                            "color-theme": "greyMedium",
                            "btn-style": "outline",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return btn.fn.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-left d-flex align-center justify-space-between px-2",
                              staticStyle: { width: "100%" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "mew-heading-2 textDark--text" },
                                [_vm._v(" " + _vm._s(btn.label) + " ")]
                              ),
                              _c("img", {
                                staticClass: "mr-4 d-none d-sm-block",
                                attrs: { width: "80", src: btn.icon },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                _c("input", {
                  ref: "jsonInput",
                  staticStyle: { display: "none" },
                  attrs: { type: "file", name: "file" },
                  on: { change: _vm.uploadFile },
                }),
              ],
              2
            )
          : _vm._e(),
        _vm.step === 3
          ? _c("div", { staticClass: "text-center" }, [
              _vm.isKeystore
                ? _c(
                    "div",
                    [
                      _c("mew-input", {
                        ref: "passwordInput",
                        attrs: {
                          label: "Enter Password",
                          placeholder: "Enter my keystore password",
                          type: "password",
                        },
                        model: {
                          value: _vm.password,
                          callback: function ($$v) {
                            _vm.password = $$v
                          },
                          expression: "password",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isMnemonic
                ? _c(
                    "div",
                    [
                      _c(
                        "phrase-block",
                        { staticClass: "mb-8" },
                        [
                          _c(
                            "v-row",
                            _vm._l(_vm.length, function (n) {
                              return _c(
                                "v-col",
                                {
                                  key: "mnemonicInput" + n,
                                  attrs: {
                                    cols: "6",
                                    lg: "3",
                                    md: "3",
                                    sm: "4",
                                  },
                                },
                                [
                                  _c("v-text-field", {
                                    ref: "mnemonicInput" + n,
                                    refInFor: true,
                                    attrs: {
                                      name: "mnemonicInput" + n,
                                      label: n + ".",
                                      autocomplete: "off",
                                      autofocus: n === 1,
                                    },
                                    model: {
                                      value: _vm.phrase[n],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.phrase, n, $$v)
                                      },
                                      expression: "phrase[n]",
                                    },
                                  }),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.step === 4
          ? _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c("mew-sheet", { staticClass: "pa-4" }, [
                  _c("div", { staticClass: "mew-heading-3 black--text" }, [
                    _vm._v(
                      " Setting address: " +
                        _vm._s(_vm.executionAddress) +
                        " as the withdrawal address. "
                    ),
                  ]),
                ]),
                _c("mew-warning-sheet", {
                  staticClass: "my-4",
                  attrs: {
                    title: "Please verify",
                    description:
                      "The withdrawal address can only be set once. Please make sure you are setting the correct address.",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "mb-2 text-center d-flex align-center justify-center",
          },
          [
            _vm.step > 1
              ? _c("mew-button", {
                  staticClass: "mr-2",
                  attrs: {
                    title: "Back",
                    "btn-style": "outline",
                    "btn-size": "xlarge",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return function () {
                        _vm.back()
                      }.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
            _vm.step != 2
              ? _c("mew-button", {
                  attrs: {
                    title: _vm.nextButton.title,
                    disabled: _vm.nextButton.disabled,
                    loading: _vm.loadingButton,
                    "btn-size": "xlarge",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return function () {
                        _vm.nextButton.fn()
                      }.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }